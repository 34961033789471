import {
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Button,
    Box,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {IQuiz} from "@/util/service/quiz/IQuiz";
import Divider from "@mui/material/Divider";
import {loadQuizHistory, loadSampleQuiz, SAMPLE_QUIZZES} from "@/util/service/quiz/content";
import {getDeviceStored, isWebApp} from "@/util/common/device";

const QuizOpenerDialog = (props: {
    setIsOpened: Function,
    changeQuiz: Function,
}) => {

    const [quizHistoryList, setQuizHistoryList] = useState<{id: number, quizTitle: string, createdAt: string}[]>([]);
    const deviceStored = getDeviceStored();

    useEffect(() => {
        fetch().then(()=>{});
        return () => {
            // clear up code
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetch = async () => {
        const loadedQuizHistoryList = await loadQuizHistory();
        setQuizHistoryList(loadedQuizHistoryList);
    }

    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log('onFileChange');
        const file = e.target.files![0];
        if(!(file instanceof Blob)) return; // file open cancel

        const fileReader = new FileReader();
        fileReader.onload = () => {
            if(typeof fileReader.result !== 'string') return; // todo: error alert
            const newQuiz: IQuiz = JSON.parse(fileReader.result); // todo: error alert

            props.changeQuiz(null, newQuiz).then(() => {
                props.setIsOpened(false);
            });
        };
        fileReader.readAsText(file);
    }

    return (<Dialog open={true}
                    fullScreen={true}
                    sx={{pr: 0, pl: 1}}>
        <TitleContainer>
            <ArrowBackIosNewIcon sx={{mr: 0}} onClick={() => {
                props.setIsOpened(false);
            }}/>
            <DialogTitle sx={{fontWeight: 'bold', mr: 10}}>
                퀴즈 열기
            </DialogTitle>
        </TitleContainer>
        <DialogContent sx={{paddingTop: 1}}>
            <Box sx={{paddingBottom: 1,
                    display: ((
                      !isWebApp() ||
                      deviceStored["app-version"] == "A1.0.3AG"
                    ) ? 'inherit': 'none')}}>
                <Button variant={'contained'} size={'small'} component='label' sx={{marginLeft: 5}}>
                    파일 열기<input type={'file'} hidden onChange={onFileChange}/>
                </Button>
            </Box>
            <List>
                {quizHistoryList.map((history, index) => (<>
                    <Divider />
                    <ListItem key={`quiz-history-${index}`}>
                        <ListItemButton onClick={async ()=> {
                            // props.setQuiz(sample);
                            // const quiz = await loadSampleQuiz(sample.url);
                            await props.changeQuiz(history.id);
                            props.setIsOpened(false);
                        }}>
                            <ListItemText primary={history.quizTitle} secondary={ <div className={'mt-2'}>
                                <Chip className={'mr-2'} label={history.createdAt.toString()} variant='outlined' size='small' color={'primary'} />
                            </div>} />
                        </ListItemButton>
                    </ListItem>
                </>))}
                {SAMPLE_QUIZZES.map((sample, index) => (<>
                    <Divider />
                    <ListItem key={`quiz-sample-${index}`}>
                        <ListItemButton onClick={async ()=> {
                            const quiz = await loadSampleQuiz(sample.url);
                            await props.changeQuiz(null, quiz);
                            props.setIsOpened(false);
                        }}>
                            <ListItemText primary={sample.name} secondary={ <div className={'mt-2'}>
                                <Chip className={'mr-2'} label={'sample'} variant='outlined' size='small' color={'secondary'} />
                            </div>} />
                        </ListItemButton>
                    </ListItem>

                </>))}
            </List>
        </DialogContent>
    </Dialog>);
}

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export { QuizOpenerDialog };
