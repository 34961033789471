import React, {useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import {Button, ButtonGroup, Pagination, TextField} from '@mui/material'
import {isEmpty, isNotEmpty, isNotNil, shallowCopy} from "@/util/common/handle-object";
import {IQuiz, ISolved} from "@/util/service/quiz/IQuiz";
import {
    loadQuizById,
    loadSampleQuiz,
    loadSolvingProgressStore,
    saveQuiz,
    updateSolvingProgressStore
} from "@/util/service/quiz/content";
import {sanitizePurify} from "@/util/common/sanitize-html-purify";
import Stack from "@mui/material/Stack";
import {QuizOpenerDialog} from "@/components/service/quiz/QuizOpenerDialog";
import {QuizEditorDialog} from "@/components/service/quiz/QuizEditorDialog";
import {styled} from "@mui/material/styles";
import {QuizHintDialog} from "@/components/service/quiz/QuizHintDialog";
import {parseQueryString, patchQueryString} from "@/util/common/query-string";
import DownloadIcon from '@mui/icons-material/Download';
import {getDeviceStored, isWebApp} from "@/util/common/device";

const isMobileView = window.innerWidth < 1100;

const QuizView = () => {

    const [total, setTotal] = useState<number>(0);
    const [progressNumber, setProgressNumber] = useState<number>(1);
    const [solved, setSolved] = useState<ISolved[]>([]);

    const [quizId, setQuizId] = useState<number|null>(null);
    const [quiz, setQuiz] = useState<IQuiz|null>(null);
    const [selectOptionNumbers, setSelectOptionNumbers] = useState<number[]>([]);
    const [textAnswer, setTextAnswer] = useState<string>('');

    const [quizOpenerDialogOpen, setQuizOpenerDialogOpen] = useState<boolean>(false);
    const [quizEditorDialogOpen, setQuizEditorDialogOpen] = useState<boolean>(false);
    const [hintDialogOpen, setHintDialogOpen] = useState<boolean>(false);

    const deviceStored = getDeviceStored();

    useEffect(() => {
        fetch().then();
        return () => { // clear up code
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetch = async () => {
        const query = parseQueryString();
        if('fileUrl' in query && typeof query['fileUrl'] === 'string' ) {
            const sampleQuiz = await loadSampleQuiz(query['fileUrl']);
            if(sampleQuiz !== null) {
                const solved = sampleQuiz.questions.map(() => {return {solverAnswers: []};});

                setQuiz(sampleQuiz);
                setSolved(solved);
                setTotal(sampleQuiz.questions.length);
            }
        } else {
            const sampleQuiz = await loadSampleQuiz();
            if(sampleQuiz !== null) {
                const solved = sampleQuiz.questions.map(() => {
                    return {solverAnswers: []};
                });

                setQuiz(sampleQuiz);
                setSolved(solved);
                setTotal(sampleQuiz.questions.length);
            }
        }
    }

    const changeQuiz = async (id: number|null = null, quiz: IQuiz|null = null) => {
        // console.log('changeQuiz');
        if(quiz === null && id === null) {
            // todo: error handling
            return;
        }

        const query = parseQueryString();
        if('fileUrl' in query ) {
            patchQueryString({fileUrl: undefined});
        }

        if(isNotEmpty(id)) {
            console.log('open quiz from db. id: ' + id);
            const newQuiz = await loadQuizById(id!);
            setQuizId(id);
            setQuiz(newQuiz);
            setTotal(newQuiz.questions.length);
            const solvingProgressStore = await loadSolvingProgressStore(id!);
            if(isEmpty(solvingProgressStore)) {
                const solved = newQuiz!.questions.map(() => {return {solverAnswers: []};});
                await updateSolvingProgressStore(id!, {progressNumber: 1, solved: solved});
                setSolved(solved);
                setProgressNumber(1);
                setSelectOptionNumbers([]);
                setTextAnswer('');
            } else {
                const newSolved = solvingProgressStore.solvingProgress.solved;
                const newProgressNumber = solvingProgressStore.solvingProgress.progressNumber;
                setSolved(newSolved);
                setProgressNumber(newProgressNumber);

                const newQuestion = newQuiz.questions[newProgressNumber-1];
                if(isEmpty(newSolved[newProgressNumber-1].solverAnswers)) {
                    setSelectOptionNumbers([]);
                    setTextAnswer('');
                } else if(newQuestion.type==='MultipleChoice') {
                    setSelectOptionNumbers([Number(newSolved[newProgressNumber-1].solverAnswers[0])]);
                    setTextAnswer('');
                } else if(newQuestion.type==='Checkboxes') {
                    setSelectOptionNumbers(newSolved[newProgressNumber-1].solverAnswers.map((solverAnswer) => { return Number(solverAnswer); }));
                    setTextAnswer('');
                } else if(newQuestion.type==='MultipleChoiceAndParagraphAnswer') {
                    setSelectOptionNumbers([Number(newSolved[newProgressNumber-1].solverAnswers[0])]);
                    setTextAnswer(newSolved[newProgressNumber-1].solverAnswers[1]);
                } else if(newQuestion.type==='NumberAnswer') {
                    setTextAnswer(newSolved[newProgressNumber-1].solverAnswers[0]);
                    setSelectOptionNumbers([]);
                } else if(newQuestion.type==='ShortAnswer') {
                    setTextAnswer(newSolved[newProgressNumber-1].solverAnswers[0]);
                    setSelectOptionNumbers([]);
                }
            }
            return;
        }

        const newId = await saveQuiz(quiz!);
        console.log('create quiz in db. id: ' + newId);
        setQuizId(newId);
        setQuiz(quiz);
        setTotal(quiz!.questions.length);

        const solved = quiz!.questions.map(() => {return {solverAnswers: []};});
        await updateSolvingProgressStore(newId, {progressNumber: 1, solved: solved});
        setSolved(solved);
        setProgressNumber(1);
        setSelectOptionNumbers([]);
        setTextAnswer('');
    }

    const onClickOption = (optionNumber: number) => {
        // 객관식 option 클릭 시 호출. MultipleChoice/MultipleChoiceAndParagraphAnswer/Checkboxes 만 사용.
        const question = quiz?.questions[progressNumber-1];
        if(isEmpty(question) || question == null) return;
        if(isEnteredAnswer()) return;

        if(question.type==='MultipleChoice') {
            if(selectOptionNumbers.includes(optionNumber)) setSelectOptionNumbers([]);
            else setSelectOptionNumbers([optionNumber]);
        } else if(question.type==='Checkboxes') {
            if(selectOptionNumbers.includes(optionNumber)) setSelectOptionNumbers(selectOptionNumbers.filter((selectOptionNumber) => selectOptionNumber !== optionNumber));
            else setSelectOptionNumbers(selectOptionNumbers.concat([optionNumber]));
        } else if(question.type==='MultipleChoiceAndParagraphAnswer') {
            if(selectOptionNumbers.includes(optionNumber)) setSelectOptionNumbers([]);
            else setSelectOptionNumbers([optionNumber]);
        }
    }

    const onChangeTextAnswer = (value: string) => {
        if(isEnteredAnswer()) return;
        setTextAnswer(value);
    }

    const onClickSubmitAnswer = () => {
        const question = quiz?.questions[progressNumber-1];
        if(isEmpty(question) || question == null) return;

        const newSolved = shallowCopy(solved);
        if(question.type==='MultipleChoice') {
            if(selectOptionNumbers.length !== 1) return;
            newSolved[progressNumber-1].solverAnswers = [selectOptionNumbers[0].toString()];
        } else if(question.type==='Checkboxes') {
            if(selectOptionNumbers.length === 0) return;
            newSolved[progressNumber-1].solverAnswers = selectOptionNumbers.map((selectOptionNumber) => { return selectOptionNumber.toString(); });
        } else if(question.type==='MultipleChoiceAndParagraphAnswer') {
            if(selectOptionNumbers.length !== 1) return;
            newSolved[progressNumber-1].solverAnswers = [selectOptionNumbers[0].toString(), textAnswer];
        } else if(question.type==='ParagraphAnswer') {
            newSolved[progressNumber-1].solverAnswers = [textAnswer];
        } else if(question.type==='NumberAnswer') {
            newSolved[progressNumber-1].solverAnswers = [textAnswer];
        } else if(question.type==='ShortAnswer') {
            newSolved[progressNumber-1].solverAnswers = [textAnswer];
        }
        setSolved(newSolved);
        if(isNotEmpty(quizId)) {
            updateSolvingProgressStore(quizId!, {progressNumber: progressNumber, solved: solved}).then();
        }
    }

    const checkCorrect = () => {
        const question = quiz?.questions[progressNumber-1];
        if(isEmpty(question) || question == null) return false; // 오류. checkNotCorrect 와 같은 결과값
        if(!isEnteredAnswer()) return false; // 답 미입력. checkNotCorrect 와 같은 결과값
        if(question.correctAnswer === undefined) return false; // 정답이 없음. checkNotCorrect 와 같은 결과값

        if(isEmpty(solved[progressNumber-1].solverAnswers)) return false;

        if(question.type==='MultipleChoice') {
            if(question.correctAnswer.toString() === solved[progressNumber-1].solverAnswers[0]) return true;
        } else if(question.type==='Checkboxes') {
            if(Array.isArray(question.correctAnswer)) {
                for(const eachCorrectAnswer of question.correctAnswer) {
                    if(!solved[progressNumber-1].solverAnswers.includes(eachCorrectAnswer.toString())) return false;
                }
                return true;
            } else {
                if(question.correctAnswer.toString() === solved[progressNumber-1].solverAnswers[0]) return true;
            }
            if(Array.isArray(question.correctAnswer).toString() === solved[progressNumber-1].solverAnswers[0]) return true;
            if(question.correctAnswer.toString() === solved[progressNumber-1].solverAnswers[0]) return true;
        } else if(question.type==='MultipleChoiceAndParagraphAnswer') {
            if(Array.isArray(question.correctAnswer)) {
                if(question.correctAnswer[0] === solved[progressNumber-1].solverAnswers[0]) return true;
            } else if(question.correctAnswer === solved[progressNumber-1].solverAnswers[0]) return true;
        } else if(question.type==='ParagraphAnswer') {
            return true;
        } else if(question.type==='NumberAnswer') {
            if(Number(question.correctAnswer) === Number(solved[progressNumber-1].solverAnswers[0])) return true;
        } else if(question.type==='ShortAnswer') {
            if(Array.isArray(question.correctAnswer)) {
                for(const eachCorrectAnswer of question.correctAnswer) {
                    if(eachCorrectAnswer === solved[progressNumber-1].solverAnswers[0]) return true;
                }
            } else {
                if(question.correctAnswer === solved[progressNumber-1].solverAnswers[0]) return true;
            }
        }
        return false;
    }

    const checkNotCorrect = () => {
        const question = quiz?.questions[progressNumber-1];
        if(isEmpty(question) || question == null) return false; // 오류. checkCorrect 와 같은 결과값
        if(!isEnteredAnswer()) return false; // 답 미입력. checkCorrect 와 같은 결과값
        if(question.correctAnswer === undefined) return false; // 정답이 없음. checkCorrect 와 같은 결과값

        // return !checkCorrect(); // todo: enable

        if(question.type==='MultipleChoice') {
            if(question.correctAnswer?.toString() !== solved[progressNumber-1].solverAnswers[0]) return true;
        } else if(question.type==='Checkboxes') {
            return !checkCorrect();
        } else if(question.type==='MultipleChoiceAndParagraphAnswer') {
            return !checkCorrect();
        } else if(question.type==='ParagraphAnswer') {
            return !checkCorrect();
        } else if(question.type==='NumberAnswer') {
            if(Number(question.correctAnswer) !== Number(solved[progressNumber-1].solverAnswers[0])) return true;
        } else if(question.type==='ShortAnswer') {
            return !checkCorrect();
        }

        return false;
    }

    const changeQuestion = (questionNumber: number) => {
        setProgressNumber(questionNumber);
        if(isNotEmpty(quizId)) {
            updateSolvingProgressStore(quizId!, {progressNumber: questionNumber, solved: solved}).then();
        }

        const question = quiz?.questions[questionNumber-1];

        if(total < questionNumber) return;
        if(quiz == null) return;
        if(isEmpty(question) || question == null) return;

        const solverAnswers = solved[questionNumber-1].solverAnswers;
        if(isEmpty(solverAnswers)) {
            setSelectOptionNumbers([]);
            setTextAnswer('');
        } else if(question.type==='MultipleChoice') {
            setSelectOptionNumbers(solverAnswers.map((solverAnswer: string) => { return Number(solverAnswer); }));
            setTextAnswer('');
        } else if(question.type==='Checkboxes') {
            setSelectOptionNumbers(solverAnswers.map((solverAnswer: string) => { return Number(solverAnswer); }));
            setTextAnswer('');
        } else if(question.type==='MultipleChoiceAndParagraphAnswer') {
            setSelectOptionNumbers([Number(solverAnswers[0])]);
            setTextAnswer(solverAnswers[1]);
        } else if(question.type==='ParagraphAnswer') {
            setTextAnswer(solverAnswers[0]);
            setSelectOptionNumbers([]);
        } else if(question.type==='NumberAnswer') {
            setTextAnswer(solverAnswers[0]);
            setSelectOptionNumbers([]);
        } else if(question.type==='ShortAnswer') {
            setTextAnswer(solverAnswers[0]);
            setSelectOptionNumbers([]);
        } else {
            setSelectOptionNumbers([]);
            setTextAnswer('');
        }
    }

    const isEnteredAnswer = () => {
        const question = quiz?.questions[progressNumber-1];
        const solverAnswers = solved[progressNumber-1].solverAnswers;
        if(question == null) return false;
        if(isEmpty(solverAnswers)) return false;
        return true;
    }

    if(quiz === null || isEmpty(quiz.questions[progressNumber-1])) {
        return (<></>);
    }

    return (<>
        <Box className={'p-2.5 max-w-3xl'}>

            <Stack className={'mb-2'} alignItems={'start'} direction="row" spacing={1}>
                <Button variant={'contained'} size={'small'} sx={{height: '22px'}}
                        onClick={() => setQuizOpenerDialogOpen(true)}
                        component='label' >열기</Button>
                { process.env.NODE_ENV === 'development' ? <>
                    <Button variant={'contained'} size={'small'} sx={{height: '22px'}}
                            onClick={() => setQuizEditorDialogOpen(true)}
                            component='label' >편집</Button>
                </>: <></>}
                <Button variant={'contained'} size={'small'}
                        sx={{height: '22px',
                            display: ((
                                !isWebApp() ||
                                deviceStored["app-version"] == "A1.0.3AG"
                            ) ? 'inherit': 'none')}}
                        onClick={() => {
                            const filename = (quiz.quizTitle ? quiz.quizTitle : 'noname') + '.mpqz.json';
                            const fileTextContent = JSON.stringify(quiz, null, 2);
                            if(deviceStored["app-version"] == "A1.0.3AG") {
                                const reactNativeWebView = (window as any).ReactNativeWebView;
                                isNotNil(reactNativeWebView) && reactNativeWebView.postMessage(JSON.stringify({
                                    type: 'download.text-file',
                                    message: {
                                        filename: filename,
                                        text: fileTextContent
                                    }
                                }));
                            } else {
                                const link = document.createElement('a');
                                link.download = filename;
                                const blob = new Blob([fileTextContent], {type: 'text/plain'});
                                link.href = window.URL.createObjectURL(blob);
                                link.click();
                            }
                        }}
                        component='label'><DownloadIcon fontSize={'small'} /></Button>
            </Stack>

            <Stack className={'mb-5' + (isMobileView? ' mr-0': ' mr-20')} alignItems={'end'}>
                <Pagination size={'small'}
                            color='primary'
                            count={total}
                            page={progressNumber}
                            onChange={(_, value)=>{changeQuestion(value);}} />
            </Stack>

            <Box>
                <div dangerouslySetInnerHTML={{__html: sanitizePurify(quiz.questions[progressNumber-1].questionBody)}}></div>

                {quiz.questions[progressNumber-1].options && quiz.questions[progressNumber-1].options!.length > 0 ? <ButtonGroup orientation="vertical" aria-label="Vertical button group" sx={{width:'90%', marginTop: '10px'}}>
                        {quiz.questions[progressNumber-1].options!.map((option, index) => (<Button
                            variant={selectOptionNumbers.includes(index+1) ? 'contained': 'outlined'}
                            sx={{width:'90%',
                                justifyContent: 'flex-start'}} // todo: optionSymbols 함수화
                            onClick={() => onClickOption(index+1)}>{quiz.questions[progressNumber-1].extraSetting && quiz.questions[progressNumber-1].extraSetting?.optionSymbols !== undefined ? quiz.questions[progressNumber-1].extraSetting!.optionSymbols![index]: optionSymbols[index]} {option}</Button>))};
                </ButtonGroup>:''}
            </Box>

            { ['MultipleChoice', 'Checkboxes'].includes(quiz.questions[progressNumber-1].type ?? '') ? '': (
                <TextField sx={{marginTop: 1, width: '85%'}}
                           multiline={['ParagraphAnswer', 'MultipleChoiceAndParagraphAnswer'].includes(quiz.questions[progressNumber-1].type ?? '')}
                           rows={ ['ParagraphAnswer', 'MultipleChoiceAndParagraphAnswer'].includes(quiz.questions[progressNumber-1].type ?? '') ? 10 : 1 }
                           type={ quiz.questions[progressNumber-1].type === 'NumberAnswer' ? 'number' : 'text'}
                           value={textAnswer}
                           onChange={(event) => onChangeTextAnswer(event.target.value)} />
            )}

            {isEnteredAnswer() ? '': <Box className={'mt-2'} sx={{paddingLeft:'30%'}}>
                    {isEmpty(quiz.questions[progressNumber-1].help?.hint) ? '':
                        <Button sx={{borderColor: 'pink', color: 'hotpink', marginRight: '20px'}} variant={'outlined'} onClick={() => setHintDialogOpen(true)}>힌트</Button>}
                    <Button variant={'outlined'} onClick={() => onClickSubmitAnswer()}>확인</Button>
                </Box>}

            {checkCorrect() ? <Box className={'mt-4'} sx={{paddingLeft:'5%'}}>
                🙆🏻‍<span className={'text-blue-700 font-bold'}>정답입니다.</span>😀
            </Box>: ''}
            {checkNotCorrect() ? <Box className={'mt-4'} sx={{paddingLeft:'5%'}}>
                🙅🏻‍<span className={'text-fuchsia-700 font-bold'}>️오답이에요.</span>😭
            </Box>: ''}

            {isEnteredAnswer() && isNotEmpty(quiz.questions[progressNumber-1].help?.summary) ? <Box
                className={'border-2 rounded-md mt-4 pb-2' + (checkNotCorrect() ? ' border-fuchsia-400' : ' border-blue-400')}
                sx={{width:'85%'}}>
                <div className='pt-1'>
                    <span className={'' + (checkNotCorrect() ? 'bg-fuchsia-400' : 'bg-blue-300')}>요약 설명</span></div>
                <SummaryHtmlInner dangerouslySetInnerHTML={{__html: sanitizePurify(quiz.questions[progressNumber-1].help!.summary!)}} />
            </Box> : ''}

            {isEnteredAnswer() && isNotEmpty(quiz.questions[progressNumber-1].help?.explanation) ? <Box
                className={'border-2 border-amber-500 rounded-md mt-2 pb-2'}
                sx={{width: '85%'}}>
                <div className='pt-1'><span className='bg-amber-400'>상세 설명</span></div>
                <ExplanationHtmlInner dangerouslySetInnerHTML={{__html: sanitizePurify(quiz.questions[progressNumber - 1].help!.explanation!)}} />
            </Box> : ''}

            {isEnteredAnswer() && progressNumber < total ? <Box className={'mt-2'} sx={{paddingLeft: '30%'}}>
                <Button variant={'outlined'} onClick={() => {changeQuestion(progressNumber+1)}}>다음</Button>
            </Box>: ''}
        </Box>

        {quizOpenerDialogOpen ? <QuizOpenerDialog setIsOpened={setQuizOpenerDialogOpen} changeQuiz={changeQuiz} /> :''}
        {quizEditorDialogOpen ? <QuizEditorDialog setIsOpened={setQuizEditorDialogOpen} changeQuiz={changeQuiz} originQuiz={quiz} /> :''}
        {hintDialogOpen ? <QuizHintDialog setIsOpened={setHintDialogOpen} htmlInner={quiz.questions[progressNumber-1].help?.hint ?? ''} /> :''}
    </>)
}

const optionSymbols = ['A.', 'B.', 'C.', 'D.', 'E.']

const SummaryHtmlInner = styled('div')`
    margin-top: 10px;
    margin-bottom: 10px;
    
    ul {
        list-style: '- ';
        padding-left: 20px;
    }
`;

const ExplanationHtmlInner = styled('div')`
    margin-top: 20px;
    margin-bottom: 20px;
    
    ul {
        list-style: '- ';
        padding-left: 20px;
    }
`;

const SAMPLE_QUIZ = {
    "quizTitle": "맞춤법 퀴즈",
    "questions": [{
        "questionBody": "<p style='font-size:smaller;'>밑줄의 맞춤법이 맞을까요?<p/><p style='padding-left:30px;padding-top:20px;padding-bottom:10px;'>치킨이 나온게 언제인데 <u><b>금새</b></u> 다 먹었냐?</p>",
        "type": "MultipleChoice",
        "options": ["O","X"],
        "correctAnswer": 2,
        "help": {
            "summary": "<p>\"<b>금세</b>\"가 맞는 맞춤법입니다.</p><p>치킨이 나온 게 언제인데 <u><b>금세</b></u> 다 먹었냐?</p>"
        },
        "extraSetting": {
            "optionSymbols": ["", ""]
        }
    }, {
        "questionBody": "<p style='font-size:smaller;'>밑줄의 맞춤법이 맞을까요?<p/><p style='padding-left:30px;padding-top:20px;padding-bottom:10px;'>파전을 크게 부치려고 하는데 <u><b>넓직한</b></u> 프라이팬이 있을까?</p>",
        "type": "MultipleChoice",
        "options": ["O","X"],
        "correctAnswer": 2,
        "help": {
            "summary": "<p>\"<b>널찍한</b>\"이 맞는 맞춤법입니다.</p><p>파전을 크게 부치려고 하는데 <u><b>널찍한</b></u> 프라이팬이 있을까?</p>"
        },
        "extraSetting": {
            "optionSymbols": ["", ""]
        }
    }]
}

export default QuizView;
