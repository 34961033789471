import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import tinymce from 'tinymce';
import { Editor as TEditor } from 'tinymce';
import axios from "axios";

const WysiwygEditor = (props: {
  textValue: string,
  defaultHeight: number,
  setContentText: Function,
  contentParamOption?: string,
}) => {
    const editorRef = useRef<TEditor | null>(null);
    return (
        <div>
            <Editor
                tinymceScriptSrc='/content/tool/tinymce/tinymce.min.js'

                onInit={(_evt, editor) => editorRef.current = editor}
                onEditorChange = {(newValue, editor) => {
                  if(props.contentParamOption) {
                    props.setContentText(newValue, props.contentParamOption);
                  } else {
                    props.setContentText(newValue);
                  }
                }}
                value={props.textValue}
                init={{
                    apiKey: 'gpl',
                    height: props.defaultHeight,
                    menubar: false,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount', 'image'
                    ],
                    toolbar: 'undo redo | blocks | image | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | code | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    branding: false,
                    image_title: true,
                    images_file_types: 'jpg,svg,webp',
                    file_picker_types: 'image',
                    file_picker_callback: async (callback, value, meta) => {
                      if (meta.filetype == 'image') {
                        const input = document.createElement('input');
                        input.setAttribute('type', 'file');
                        input.setAttribute('accept', 'image/*');
                        input.addEventListener('change', async (event: any) => {
                          if(!event.target) return;
                          if(!event.target.files) return;
                          const file = event.target.files[0];
                          const reader = new FileReader();
                          reader.addEventListener('load', async () => {
                            const formData = new FormData();
                            const fileName = 'file_name'
                            formData.append('file', file);
                            const response = await axios.post(process.env.REACT_APP_API_URL + '/api/mp_webapp/upload_image/ibb', formData);
                            callback(response.data.result.image_url);
                          });
                          reader.readAsDataURL(file);
                        });
                        input.click();
                      }
                    },
                }}
            />
        </div>
    );
}

const handleFilePickerCallback = async (callback: Function, value: string, meta: any) => {
  if (meta.filetype == 'image') {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.addEventListener('change', async (event: any) => {
      if(!event.target) return;
      if(!event.target.files) return;
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.addEventListener('load', async () => {
        const formData = new FormData();
        const fileName = 'file_name'
        formData.append('file', file);
        const response = await axios.post(process.env.REACT_APP_API_URL + '/api/mp_webapp/upload_image/ibb', formData);
        callback(response.data.result.image_url);
      });
      reader.readAsDataURL(file);
    });
    input.click();
  }
}

export default WysiwygEditor;
