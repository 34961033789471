import {
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Pagination,
    Button,
    Box,
    TextField,
} from "@mui/material";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import React, {useState, useEffect} from "react";
import styled from "styled-components";
import WysiwygEditor from "@/components/service/quiz/WysiwygEditor";
import {IQuiz, ISolved} from "@/util/service/quiz/IQuiz";

import {sanitizePurify} from "@/util/common/sanitize-html-purify";
import Stack from "@mui/material/Stack";
import {QuizHintDialog} from "@/components/service/quiz/QuizHintDialog";
import {parseQueryString, patchQueryString} from "@/util/common/query-string";
import { isEmpty, deepCopy, shallowCopy } from "@/util/common/handle-object";

const isMobileView = window.innerWidth < 1100;

const QuizEditorDialog = (props: {
    setIsOpened: Function,
    changeQuiz: Function,
    originQuiz: IQuiz,
}) => {
    const [progressNumber, setProgressNumber] = useState<number>(1);
    // const [quiz, setQuiz] = useState<IQuiz>(props.originQuiz);
    const [quiz, setQuiz] = useState<IQuiz>(deepCopy(props.originQuiz));
    const [wysiwygQuestionBody, setWysiwygQuestionBody] = useState<string>('');
    const [wysiwygQuestionOptions, setWysiwygQuestionOptions] = useState<Array<string>>([]);

    useEffect(() => {
        fetch().then();
        return () => { // clear up code
            clear().then();
        }
    }, []);

    if(isEmpty(quiz) || isEmpty(quiz.questions)) return null;

    const fetch = async () => {
        if(isEmpty(quiz) || isEmpty(quiz.questions)) return null;
        setWysiwygQuestionBody(quiz.questions[progressNumber-1].questionBody);
    }

    const clear = async () => {
    }

    const changeQuestion = (questionNumber: number) => {
        setProgressNumber(questionNumber);
        const question = quiz?.questions[questionNumber-1];
        if(quiz == null) return;
        if(isEmpty(question) || question == null) return;
    }

    const changeQuestionBody = (newText: string) => {
        setWysiwygQuestionBody(newText);
        const newQuiz = shallowCopy(quiz);
        newQuiz.questions[progressNumber-1].questionBody = newText;
        setQuiz(newQuiz);
    }

    const changeQuestionOptions = (newText: string, contentParamOption: string) => {
        const newOptions = shallowCopy(wysiwygQuestionOptions);
        newOptions[Number(contentParamOption)-1] = newText;
        setWysiwygQuestionOptions(newOptions);
    }

    return (<Dialog open={true}
                    fullScreen={true}
                    sx={{pr: 0, pl: 1}}>

        
        <TitleContainer>
            <ArrowBackIosNewIcon sx={{mr: 0}} onClick={() => {
                props.setIsOpened(false);
            }}/>
            <DialogTitle sx={{fontWeight: 'bold', mr: 10}}>
                Quiz Editor
            </DialogTitle>
        </TitleContainer>
        <DialogContent sx={{paddingTop: 1}}>

            <Box className={'border-b-blue-300 border-2'}>
                <TextField
                  label="Quiz Name"
                  variant="outlined"
                  // value={text}
                  // onChange={handleChange}
                  // fullWidth
                />

            </Box>

            <Button variant={'contained'} size={'small'} component='label' sx={{height: '22px'}}>저장</Button>

            <Stack className={'mb-5' + (isMobileView? ' mr-0': ' mr-20')} alignItems={'end'}>
                <Pagination size={'small'}
                            color='primary'
                            count={quiz.questions.length}
                            page={progressNumber}
                            onChange={(_, value)=>{changeQuestion(value);}} />
            </Stack>

            <Box className={'border-b-blue-300 border-2'}>
                퀴즈 유형
                <div>{quiz.questions[progressNumber-1].type}</div>
            </Box>
            <Box className={'border-b-blue-300 border-2'}>
                퀴즈 본문
                <div dangerouslySetInnerHTML={{__html: sanitizePurify(quiz?.questions[progressNumber-1].questionBody)}}
                     className={'border-amber-900 border-2'} />
            </Box>
            <Box className={'border-b-blue-300 border-2'}>
                정답 : {quiz.questions[progressNumber-1].correctAnswer}
                {/*<div dangerouslySetInnerHTML={{__html: sanitizePurify(quiz?.questions[progressNumber-1].correctAnswer)}}></div>*/}
            </Box>
            <Box className={'border-b-blue-300 border-2'}>
                {quiz.questions[progressNumber - 1].help?.summary ? <>
                  요약 설명
                  <div dangerouslySetInnerHTML={{__html: sanitizePurify(quiz.questions[progressNumber - 1].help!.summary!)}}
                       className={'border-amber-900 border-2'} />
                </>: <></>}
            </Box>
            <Box className={'border-b-blue-300 border-2'}>
                상세 설명
                {quiz.questions[progressNumber - 1].help?.explanation ?
                    <div dangerouslySetInnerHTML={{__html: sanitizePurify(quiz.questions[progressNumber-1].help!.explanation!)}}
                         className={'border-amber-900 border-2'} />: <></>}
            </Box>
            <Box className={'border-b-blue-300 border-2'}>
                힌트
                {quiz.questions[progressNumber - 1].help?.hint ?
                    <div dangerouslySetInnerHTML={{__html: sanitizePurify(quiz.questions[progressNumber-1].help!.hint!)}}
                         className={'border-amber-900 border-2'}/>: <></>}
            </Box>

            <Box>
                퀴즈 본문 편집
                <WysiwygEditor
                    setContentText={changeQuestionBody}
                    textValue={wysiwygQuestionBody}
                    defaultHeight={500} />
            </Box>

            {/*해설*/}
            {/*<WysiwygEditor*/}
            {/*    setContentText={changeQuestionBody}*/}
            {/*    textValue={wysiwygQuestionBody}*/}
            {/*    defaultHeight={500} />*/}

            
            {/* <WysiwygEditor defaultText={quiz.questions[progressNumber-1].questionBody} defaultHeight={200} /> */}
            {/* <WysiwygEditor defaultText={quiz.questions[progressNumber-1].questionBody} defaultHeight={200} /> */}
            {/* <WysiwygEditor defaultText={quiz.questions[progressNumber-1].questionBody} defaultHeight={200} /> */}
            {/* <WysiwygEditor defaultText={quiz.questions[progressNumber-1].questionBody} defaultHeight={200} /> */}
            {/* <WysiwygEditor defaultText={quiz.questions[progressNumber-1].questionBody} defaultHeight={200} /> */}

        </DialogContent>
    </Dialog>);
}

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export { QuizEditorDialog };
