import {getLocalStorage, setLocalStorage} from "@/util/common/web-storage";

function getRandomInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const decimalToBase36 = (num: number): string => {
  return num.toString(36);
}

const makeMpDeviceId = () => {
  const timestamp: number = new Date().getTime();
  const timePart = decimalToBase36(timestamp);
  const randomNumber = getRandomInt(0, 60466175); // 36진수 zzzzz의 10진수 변환: 60466175
  const randomPart = decimalToBase36(randomNumber).padStart(5, '0');
  return timePart + randomPart;
}

const isAndroidWeb = () => {
  const userAgent = navigator.userAgent.toLowerCase(); //userAgent 문자열 값 받아오기

  if (userAgent.indexOf("android") > -1) {
    return true;
  }
  return false;
}

const isIosWeb = () => {
  const userAgent = navigator.userAgent.toLowerCase(); //userAgent 문자열 값 받아오기
  if (userAgent.indexOf("iphone") > -1) return true;
  if (userAgent.indexOf("ipad") > -1) return true;
  return false;
}

interface IVoice {
  identifier: string,
  lang: string,
  name: string,
  quality: string,
  originIndex: number,
}

interface ITTS {
  voices: Array<IVoice>;
}

export default interface IDeviceStored {
  "mp-device-id": string;
  "platform-os": string;
  "app-version"?: string;
  tts?: ITTS;
}

const getDeviceStored = (): IDeviceStored => {
  const deviceStored = getLocalStorage('device');
  if(deviceStored) return deviceStored;

  const newDeviceStored = {
    'mp-device-id': makeMpDeviceId(),
    'platform-os': "unknown",
  };
  if(isAndroidWeb()) newDeviceStored['platform-os'] = 'android'
  else if(isIosWeb()) newDeviceStored['platform-os'] = 'ios'
  setLocalStorage('device', newDeviceStored);
  return newDeviceStored;
}


const isWebApp = () => {
  return !!getDeviceStored()["app-version"];
}

export { getDeviceStored, isWebApp };
